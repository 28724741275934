import React from 'react';
import useQuery from '@spot/shared-hooks/useQuery.hook';
import { useEffect, useRef } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions.hook';
import Button from '../../components/Button/Button.component';
import cls from '../../hooks/cls.util';
import logo from '../../assets/logoSpotView.png';
import styles from './Redirecting.module.css';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import accounts from '../../store/accounts';
import { flow } from 'lodash';

export default function Redirecting() {
  const { width } = useWindowDimensions();
  const isTabletAndPhones = width < 900;

  const hasError = useRef(false);
  const isLoading = useRef(true);

  const query = useQuery();

  const token = query.get('token');
  const variant = query.get('variant');
  const type = query.get('type');
  const redirectUrl = query.get('redirectUrl');

  const dispatch: Dispatch<AnyAction> = useDispatch();

  const dispatchRedux = {
    confirmEmailChange: flow(accounts.action.servicePatchChangeEmail, dispatch),
  };

  if (!type || !token || !variant) {
    hasError.current = true;

    return (
      <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
        <section className={styles.content}>
          <img className={styles.logo} alt="Logo" src={logo} />
          <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
            <div className={styles.heading}>
              <h1>Algo deu errado!</h1>
              <p>Ocorreu um erro ao tentar confirmar seus novos dados!</p>
            </div>
          </form>
        </section>
        {!isTabletAndPhones && (
          <section className={styles.illustration}>
            <span className={styles.gradient}></span>
            {!isTabletAndPhones && <span className={styles.preview}></span>}
          </section>
        )}
      </main>
    );
  }

  function goToLogin() {
    window.location.href = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${redirectUrl}&variant=spoteye`;
  }

  useEffect(() => {
    if (token && variant && type) {
      dispatchRedux.confirmEmailChange({
        token: token,
      });

      isLoading.current = false;
      return;
    }

    hasError.current = true;
    isLoading.current = false;
  }, []);

  const form = (
    <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
      {isLoading.current && (
        <div className={styles.heading}>
          <h1>Validando...</h1>
          <p>Por favor, aguarde.</p>
        </div>
      )}

      {!isLoading.current && hasError.current && (
        <div className={styles.heading}>
          <h1>Algo deu errado!</h1>
          <p>Ocorreu um erro ao tentar confirmar seus novos dados!</p>
        </div>
      )}

      {!isLoading.current && !hasError.current && (
        <div className={styles.heading}>
          <h1>Dados confirmados!</h1>
          <p>
            Seus dados foram confirmados e você já pode entrar normalmente com
            seu novo email!
          </p>
          <Button label="Ir para o log-in" onClick={goToLogin} />
        </div>
      )}
    </form>
  );

  return (
    <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
      <section className={styles.content}>
        <img className={styles.logo} alt="Logo" src={logo} />
        {form}
      </section>
      {!isTabletAndPhones && (
        <section className={styles.illustration}>
          <span className={styles.gradient}></span>
          {!isTabletAndPhones && <span className={styles.preview}></span>}
        </section>
      )}
    </main>
  );
}
