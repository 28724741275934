import React, { useEffect, useRef, useState } from 'react';
import styles from './AccountRecovery.module.css';

import logo from '../../assets/logoSpotView.png';
import useWindowDimensions from '../../hooks/useWindowDimensions.hook';
import cls from '../../hooks/cls.util';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import useQuery from '@spot/shared-hooks/useQuery.hook';
import Button from '../../components/Button/Button.component';
import { flow } from 'lodash';
import accounts from '../../store/accounts';
import jwtDecode from 'jwt-decode';

export default function AccountRecovery(): JSX.Element {
  const query = useQuery();
  const { width } = useWindowDimensions();
  const isTabletAndPhones = width < 900;

  const isLoading = useRef(true);
  const hasError = useRef(false);

  const token: any = query.get('token');
  const decodedToken: any = jwtDecode(token as any);
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const dispatchRedux = {
    servicePostProfile: flow(
      accounts.action.servicePostProfileConfirm,
      dispatch
    ),
  };

  function goToLogin() {
    window.location.href = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${process.env.REACT_APP_URL_SPOTEYE}&variant=spoteye`;
  }

  useEffect(() => {
    if (token && decodedToken && decodedToken.user) {
      dispatchRedux.servicePostProfile({
        token: token,
        account_id: decodedToken?.user.id,
      });

      isLoading.current = false;
      return;
    }

    hasError.current = true;
    isLoading.current = false;
  }, []);

  const form = (
    <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
      {isLoading.current && (
        <div className={styles.heading}>
          <h1>Carregando...</h1>
          <p>Por favor, aguarde.</p>
        </div>
      )}

      {!isLoading.current && hasError.current && (
        <div className={styles.heading}>
          <h1>Algo deu errado!</h1>
          <p>Ocorreu um erro ao tentar liberar seu acesso!</p>
        </div>
      )}

      {!isLoading.current && !hasError.current && (
        <div className={styles.heading}>
          <h1>Acesso liberado!</h1>
          <p>
            Seu acesso ao sistema foi liberado com sucesso! Você já pode
            realizar o log-in normalmente.
          </p>
          <Button label="Ir para o log-in" onClick={goToLogin} />
        </div>
      )}
    </form>
  );

  return (
    <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
      <section className={styles.content}>
        <img className={styles.logo} alt="Logo" src={logo} />
        {form}
      </section>
      {!isTabletAndPhones && (
        <section className={styles.illustration}>
          <span className={styles.gradient}></span>
          {!isTabletAndPhones && <span className={styles.preview}></span>}
        </section>
      )}
    </main>
  );
}
