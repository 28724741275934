import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  account_id: string;
  password: string;
  token: string;
};

export default async function postEmailConfirmationService(payload: Payload) {
  const { account_id, password, token } = payload;

  const url = ['accounts', `${account_id}`, 'account-email-confirmation'].join(
    '/'
  );

  return fetch(url, {
    ms: 'ACCOUNT',
    method: 'POST',
    body: JSON.stringify({ password: password }),
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
