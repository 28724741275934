import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  account_id: string;
  token: string;
};

export default async function postProfileConfirmationService(payload: Payload) {
  const { account_id, token } = payload;

  const url = [
    'accounts',
    `${account_id}`,
    'account-profile-confirmation',
  ].join('/');

  return fetch(url, {
    ms: 'ACCOUNT',
    method: 'POST',
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
