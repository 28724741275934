import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  token: string;
};

export default async function patchApiChangeEmailService(payload: Payload) {
  const url = ['accounts', 'email-change'].join('/');

  const { token } = payload;

  return fetch(url, {
    method: 'PATCH',
    ms: 'ACCOUNT',
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
