const ROUTES = {
  ROOT: '/login',
  NEW_PASSWORD: '/login/new-password',
  CONFIRM_EMAIL: '/login/confirm-email',
  RECOVERY_PROFILE: '/login/confirm-account-profile',
  REDIRECT_EMAIL: '/login/redirecting',
};

const LOGIN_ROUTES = {
  ROOT: ROUTES.ROOT,
  NEW_PASSWORD: ROUTES.NEW_PASSWORD,
  CONFIRM_EMAIL: ROUTES.CONFIRM_EMAIL,
  RECOVERY_PROFILE: ROUTES.RECOVERY_PROFILE,
  REDIRECT_EMAIL: ROUTES.REDIRECT_EMAIL,
};

export const NAMESPACE = ROUTES.ROOT;

export default LOGIN_ROUTES;
