import { takeLatest, call, put, delay, select } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';
import auth from '@spot/shared-store/auth';
import flow from 'lodash/flow';

import postApiAuthService from '@spot/shared-services/account/postApiAuth.service';
import decode from 'jwt-decode';

import dialogChangeCompany from '../dialogs/dialogChangeCompany';

import login from '.';
import misc from '@spot-spotauth/store/configs/misc';

const keyCookie = {
  spotesg: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTESG,
  spotsat: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTSAT,
  spoteye: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTEYE,
  spotprospect: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTPROSPECT,
  spotview: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTVIEW,
};

const domainCookie = {
  spotesg: process.env.REACT_APP_COOKIE_DOMAIN_SPOTESG,
  spotsat: process.env.REACT_APP_COOKIE_DOMAIN_SPOTSAT,
  spoteye: process.env.REACT_APP_COOKIE_DOMAIN_SPOTEYE,
  spotprospect: process.env.REACT_APP_COOKIE_DOMAIN_SPOTPROSPECT,
  spotview: process.env.REACT_APP_COOKIE_DOMAIN_SPOTVIEW,
};

const redirectTo = {
  spotesg: process.env.REACT_APP_URL_SPOTESG,
  spotsat: process.env.REACT_APP_URL_SPOTSAT,
  spoteye: process.env.REACT_APP_URL_SPOTEYE,
  spotview: process.env.REACT_APP_URL_SPOTVIEW,
  spotprospect: process.env.REACT_APP_URL_SPOTPROSPECT,
};

function* handleServicePost({ payload }: any) {
  const variant = yield select(misc.selector.selectVariant);

  yield put(login.action.fetchStart());

  const handlers = {
    handleOpen: flow(dialogChangeCompany.action.open, put),
  };

  const params = new URLSearchParams(window.location.search);

  const [success, result] = yield call(postApiAuthService, {
    ...payload,
    host: new URL(params.get('redirectUrl') || '')?.origin,
  });

  if (!success) {
    yield put(
      toaster.action.show({ message: result?.message, variant: 'error' })
    );

    return yield put(login.action.fetchError(result));
  }

  const { token } = result;
  const user: any = decode(token);

  yield put(
    auth.action.setToken(token, keyCookie[variant], domainCookie[variant])
  );

  if (user?.user?.companies?.length > 1) {
    yield put(dialogChangeCompany.action.open({}));
  } else {
    yield call(() => {
      const URL = redirectTo[variant];

      window.location.href = `${URL}/login/auth?token=${token}&redirectUrl=${params.get(
        'redirectUrl'
      )}`;
    });
  }

  yield put(login.action.fetchEnd(result));
}

function* watch() {
  yield takeLatest(login.constant.ACTION_TYPES.SERVICE.POST, handleServicePost);
}

export default {
  watch,
};
