import flow from 'lodash/flow';
import pickBy from 'lodash/pickBy';
import { takeLatest, select, call, put, delay } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';

import getApiAccountsService from '@spot/shared-services/account/getApiAccounts.service';
import getApiAccountsIdService from '@spot/shared-services/account/getApiAccountsId.service';
import patchApiAccountsIdService from '@spot/shared-services/account/patchApiAccountsId.service';
import deleteApiAccountsIdService from '@spot/shared-services/account/deleteApiAccountsId.service';
import postEmailConfirmationService from '@spot/shared-services/account/postEmailConfirmation.service';
import postProfileConfirmationService from '@spot/shared-services/account/postProfileConfirmation.service';
import patchApiChangeEmailService from '@spot/shared-services/account/patchApiChangeEmail.service';
import patchApiChangeUnconfirmedEmailService from '@spot/shared-services/account/patchApiChangeUnconfirmedEmail.service';

import getApiAccountsid from '@spot/shared-services/account/getApiAccountsId.service';

import currentState from '.';
import misc from '@spot-spotauth/store/configs/misc';

const keyCookie = {
  spotesg: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTESG,
  spotsat: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTSAT,
  spoteye: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTEYE,
  spotprospect: process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTPROSPECT,
};

const domainCookie = {
  spotesg: process.env.REACT_APP_COOKIE_DOMAIN_SPOTESG,
  spotsat: process.env.REACT_APP_COOKIE_DOMAIN_SPOTSAT,
  spoteye: process.env.REACT_APP_COOKIE_DOMAIN_SPOTEYE,
  spotprospect: process.env.REACT_APP_COOKIE_DOMAIN_SPOTPROSPECT,
};

const redirectTo = {
  spotesg: process.env.REACT_APP_URL_SPOTESG,
  spotsat: process.env.REACT_APP_URL_SPOTSAT,
  spoteye: process.env.REACT_APP_URL_SPOTEYE,
  spotprospect: process.env.REACT_APP_URL_SPOTPROSPECT,
};

const handleServiceGet = function* (action) {
  const seletors = {
    filters: yield select(currentState.selector.selectFilters),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() =>
      getApiAccountsService(seletors.filters)
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePostEmailConfirm = function* (action) {
  const variant = yield select(misc.selector.selectVariant);

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();
    const [success, result] = yield call(
      postEmailConfirmationService as any,
      payload
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Senha atualizada com sucesso!',
      variant: 'success',
    });

    const URL = redirectTo[variant];

    window.location.href = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${URL}&variant=${variant}`;

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePostProfileConfirm = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();
    const [success, result] = yield call(
      postProfileConfirmationService as any,
      payload
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Acesso liberado!',
      variant: 'success',
    });

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });
  }
};

const handleServicePatchEmailChange = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(patchApiChangeEmailService, payload);

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Email alterado com sucesso!',
      variant: 'success',
    });

    return yield handlers.fetchEnd(result);
  } catch (error) {
    yield handlers.show({
      message: 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(error);
  }
};

const handleServicePatchChangeUnconfirmedEmail = function* (action) {
  const variant = yield select(misc.selector.selectVariant);

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();
    const [success, result] = yield call(
      patchApiChangeUnconfirmedEmailService as any,
      payload
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Email confirmado com sucesso!',
      variant: 'success',
    });

    const URL = redirectTo[variant];

    window.location.href = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${URL}&variant=${variant}`;

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const variant = yield select(misc.selector.selectVariant);

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();
    const [success, result] = yield call(
      patchApiAccountsIdService as any,
      payload?.account_id,
      pickBy({
        name: payload?.nome,
        email: payload?.email,
        password: payload?.senha,
        token: payload?.token,
        empty_photo: payload?.empty_photo,
        photo: payload?.photo?.file,
        email_confirmed: payload?.email_confirmed,
      })
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário atualizado com sucesso',
      variant: 'success',
    });

    const URL = redirectTo[variant];

    window.location.href = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${URL}&variant=${variant}`;

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceDelete = function* (action) {
  const selectors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload = {} } = action || {};

  const { account_id } = payload;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      deleteApiAccountsIdService,
      account_id
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário removido com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(selectors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;
  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(getApiAccountsid, payload?.account_id);

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_ID,
    handleServiceGetId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST_EMAIL,
    handleServicePostEmailConfirm
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST_PROFILE_EMAIL,
    handleServicePostProfileConfirm
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH_CHANGE_EMAIL,
    handleServicePatchEmailChange
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH_CHANGE_UNC_EMAIL,
    handleServicePatchChangeUnconfirmedEmail
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.DELETE,
    handleServiceDelete
  );
}

export default {
  watch,
};
