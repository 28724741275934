import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  token: string;
  password: string;
};

export default async function patchApiChangeUnconfirmedEmailService(
  payload: Payload
) {
  const url = ['accounts', 'unconfirmed-email-change'].join('/');

  const { token, password } = payload;

  return fetch(url, {
    method: 'PATCH',
    ms: 'ACCOUNT',
    body: JSON.stringify({ password: password }),
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
