import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import styles from './Login.module.css';
import logo from '../../assets/logoSpotView.png';
import TextInput from '../../components/TextInput/TextInput.component';
import PasswordInput from '../../components/PasswordInput/PasswordInput.component';
import useWindowDimensions from '../../hooks/useWindowDimensions.hook';
import cls from '../../hooks/cls.util';
import Button from '../../components/Button/Button.component';
import { Modal } from '../../components/Modal';
import useAuthHook from '@spot/shared-hooks/useAuth.hook';
import { useDispatch, useSelector } from 'react-redux';
import authenticate from '../../store/authenticate';
import dialogChangeCompany from '../../store/dialogs/dialogChangeCompany';
import useAuth from '@spot/shared-hooks/useAuth.hook';
import { flow } from 'lodash';
import LogoComponent from '@spot-spotauth/components/Logo.component';
import misc from '@spot-spotauth/store/configs/misc';
import toaster from '@spot/shared-store/toaster';
import { disconnect } from 'process';
import { reset, unsetToken } from '@spot/shared-store/auth/auth.action';

export default function Login(): JSX.Element {
  const { width } = useWindowDimensions();
  const isTabletAndPhones = width < 900;

  const variantState = useSelector(misc.selector.selectVariant);

  const [loginInfo, setLoginInfo] = useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState<number>(0); // 0 = login form, 1 = company select modal (if applicable)

  const emailSentModalRef = useRef<HTMLDialogElement>(null);
  const forgotPasswordModalRef = useRef<HTMLDialogElement>(null);

  const dispatch = useDispatch();

  const actionsSelector = {
    toaster: useSelector(toaster.selector.selectState),
    login: useSelector(authenticate.selector.selectError),
    loading: useSelector(authenticate.selector.selectLoading),
  };

  const dispatchActions = {
    login: flow(authenticate.action.servicePost, dispatch),
    select: flow(dialogChangeCompany.action.servicePost, dispatch),
  };

  const { token } = useAuth();
  let { user } = useAuthHook();
  user = user ? user : { user: [] };

  function onEmailSent(email: string) {
    emailSentModalRef.current?.showModal();
    setEmail(email);
  }

  function onForgotPassword(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    forgotPasswordModalRef.current?.showModal();
  }

  function onGoBack(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    dispatch(reset());

    setLoginInfo({ email: '', password: '' });
    setStep(0);
  }

  function onCompanySelect(company_id: string) {
    dispatchActions.select({ company_id, token: token });
  }

  function onLogin(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    dispatchActions.login(loginInfo);
  }

  useEffect(() => {
    function changeStep() {
      if (user?.user?.companies && user?.user?.companies.length > 1) {
        setStep(1);
      }
    }

    changeStep();
  }, [actionsSelector.login]);

  const form = (
    <form
      method="POST"
      className={cls(step === 0, styles.form, styles.none, styles.fadeOut)}
    >
      <div className={styles.heading}>
        <h1>Seja bem-vindo!</h1>
        <p>Por favor, preencha com suas credenciais.</p>
      </div>
      <fieldset>
        <TextInput
          name="Email"
          onChange={(e) =>
            setLoginInfo((prev) => ({ ...prev, email: e.target.value }))
          }
        />
        <PasswordInput
          name="Senha"
          onChange={(e) =>
            setLoginInfo((prev) => ({ ...prev, password: e.target.value }))
          }
          iconPassword={true}
        />
      </fieldset>
      <div className={styles.actions}>
        <button type="button" onClick={onForgotPassword}>
          Esqueci a senha
        </button>
        <Button
          buttonProps={{ type: 'submit' }}
          label="Entrar"
          onClick={onLogin}
        />
      </div>
    </form>
  );

  const companies = (
    <article className={cls(step === 1, styles.companies, styles.fadeIn)}>
      <button className={styles.goBack} onClick={onGoBack}>
        ← Voltar
      </button>
      <div className={styles.heading}>
        <h1>Seja bem-vindo de volta!</h1>
        <p>Selecione a companhia que deseja acessar.</p>
      </div>
      <ul className={styles.list}>
        {user?.user?.companies?.length === 0 && (
          <li>Nenhuma empresa encontrada</li>
        )}

        {!user?.user?.companies && <li>Carregando...</li>}

        {user?.user?.companies &&
          user?.user?.companies.map((company: any, index: number) => (
            <li key={index} onClick={() => onCompanySelect(company.id)}>
              {company.description}
            </li>
          ))}
      </ul>
    </article>
  );

  return (
    <main className={cls(isTabletAndPhones, styles.main, styles.mobile)}>
      <section className={styles.content}>
        {/* <img className={styles.logo} alt="Logo" src={logo} /> */}
        <div className={styles.teste}>
          <LogoComponent
            style={{ width: variantState === 'spotprospect' ? 300 : 220 }}
          />
        </div>
        {step === 0 && form}
        {step === 1 && companies}
      </section>
      {!isTabletAndPhones && (
        <section className={styles.illustration}>
          <span className={styles.gradient}></span>
          {!isTabletAndPhones && (
            <span
              className={`${styles.preview} ${
                variantState === 'spotsat'
                  ? styles.previewSpotsat
                  : variantState === 'spoteye'
                  ? styles.previewSpotEye
                  : variantState === 'spotesg'
                  ? styles.previewSpotesg
                  : variantState === 'spotprospect'
                  ? styles.previewSpotProspect
                  : variantState === 'spotview'
                  ? styles.previewSpotView
                  : ''
              }`}
            ></span>
          )}
        </section>
      )}

      <Modal.ForgotPassword
        forwardRef={forgotPasswordModalRef}
        onSend={onEmailSent}
      />
      <Modal.EmailSent forwardRef={emailSentModalRef} value={email} />
    </main>
  );
}
